/* stylelint-disable at-rule-name-case */
/* stylelint-disable selector-class-pattern */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

img {
  border-style: none;
  vertical-align: middle;
}

#root {
  height: 100%;
}

#parent-area {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

.panel-logo {
  margin-bottom: 30px;
}

.select-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 45px;
  height: 64px;
  float: right;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5 !important;
  }
}

.rightDropDown {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  float: right;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
    color: black;
  }
}

.content-warp-card {
  box-shadow: 0 1px 5px 0 rgb(51 51 51 / 14%);
  margin: 5px;
  flex: 1;
  align-items: stretch;
}

.side-image {
  display: none;

  @media screen and (min-width: 1100px) {
    display: block;
    position: relative;
    width: 500px;
    border-right: 0.5px solid rgb(196 203 215);
  }
}

.forget-content {
  padding: 10px 100px 20px;
  margin: 30px auto;
  border: 2px solid #fff;
  border-radius: 7px;
  background-color: rgb(255 255 255);
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
}

.login-panel {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  background-color: rgb(255 255 255);
  overflow: hidden;
}

.login-form {
  text-align: center;
  padding: 30px;
}

.login-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
}

.loginBackground {
  flex: auto;
  display: flex;
  align-items: center;
  background: #fff no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}

.ant-menu-horizontal {
  border-bottom: none !important;
}

@primary-color: rgb(89,54,213);@border-radius-base: 5px;